<template>
  <v-container class="pa-4">
    <v-row>
      <v-col cols="12" md="8" class="pa-0">
        <v-card-title class="px-4">Customer Market Report: Filtered By {{ title }}</v-card-title>
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-end">
        <v-btn text to="/market">Back To Customer Market Report</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showChart">
        <v-col cols="12" md="6" >
             <div id="chart-1">
                <apexchart type="bar" height="400" :options="chartOptionsAge" :series="seriesAge" v-if="showChart"></apexchart>
            </div>
        </v-col>
        <v-col cols="12" md="6">
             <div id="chart-2">
                <apexchart type="pie" height="400" :options="chartOptionsGender" :series="seriesGender" v-if="showChart"></apexchart>
            </div>
        </v-col>
        <v-col cols="12" md="6" >
             <div id="chart-3">
                <apexchart type="bar" height="400" :options="chartOptionsPayment" :series="seriesPayment" v-if="showChart"></apexchart>
            </div>
        </v-col>
        <v-col cols="12" md="6" >
             <div id="chart-3">
                <apexchart type="bar" height="400" :options="chartOptionsLocation" :series="seriesLocation" v-if="showChart"></apexchart>
            </div>
        </v-col>
        <v-col cols="12" md="12">
             <div id="chart-2">
                <apexchart type="bar" height="600" :options="chartOptionsAveragePurchase" :series="seriesAveragePurchase" v-if="showChart"></apexchart>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
import reportingApi from '../../clients/reportingApi';

// @ is an alias to /src
export default {
  name: "Ticket",
  components: {
          apexchart: VueApexCharts,
        },
  data() {
    return{
        index: 0,
        title: "",
        dialog: false,
        modal: false,
        dateFilterString: '',
        dateFilter: null,
        date: null,
        showChart: false,
        filter: null,
        seriesAge: [{
            data: []
        }],
        seriesPayment: [{
            data: []
        }],
        chartOptionsAge: {
            title:{
              text:"Age"
            },
            chart: {
              type: 'bar',
              height: 400
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [],
            }
        },
         chartOptionsPayment: {
            title:{
              text:"Payment Method"
            },
            chart: {
              type: 'bar',
              height: 400,
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [],
            }
        },
        seriesGender: [],
        chartOptionsGender: {
            title:{
              text:"Gender"
            },
            chart: {
              type: 'pie',
              height: 400,
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                 tools: {
                  download: true,
                  selection: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: []
                },
              }
            },
            labels: ['Male', 'Female', 'Uknown'],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: '100%'
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
        },
        seriesAveragePurchase: [{
            data: []
        }],
        chartOptionsAveragePurchase: {
            title:{
              text:"Average Purchase"
            },
            chart: {
              type: 'bar',
              height: 600
            },
            plotOptions: {
              bar: {
                borderRadius: 4,
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: [],
            }
        },
        seriesLocation: [{
            name: 'Location',
            data: []
          }],
        chartOptionsLocation: {
            title:{
              text:"Location"
            },
            chart: {
              type: 'bar',
              height: 400
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: true
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
              }
            }
          },
    }
  },
  computed: {
    isEligible(){
      return this.$store.state.isEligible;
    },
    isExpired(){
      console.log(this.$store.state.eligibleExpiration)
      return new Date().getTime() > this.$store.state.eligibleExpiration;
    }
  },
  watch: {
    isEligible(newValue){
      if(!newValue){
        this.$store.dispatch('setAlert');
        this.$router.push('/')
      }
    },
    isExpired(newValue){
      console.log(newValue);
      if(newValue){
        this.$store.dispatch('destroyAuth')
        this.$store.dispatch('setAlert', "Token Expired");
        this.$router.push('/')
      }
    }
  },  
  created () {
    console.log("check")
    if(!this.isEligible){
      this.$store.dispatch('setAlert');
      this.$router.push('/')
    }
    if(this.isExpired){
      this.$store.dispatch('destroyAuth')
      this.$store.dispatch('setAlert', "Token Expired");
      this.$router.push('/')
    }
    if (!this.$route.params.slug){
        this.$router.push('/market');
    }
    console.log(this.$route.params);
    const slug = this.$route.params.slug;
    const slugs = slug.split("+");
    console.log(slugs)
    const filterBy = slugs[0];
    const filterValue = slugs[1];
    const filterDate = slugs[2];
    const filterContent = slugs[3]
    if([filterBy, filterValue, filterDate].includes(undefined)){
        this.$router.push('/market');
    }
    this.title = filterBy.split("-").join(" ").toUpperCase();
    this.generate(filterBy, filterValue, filterDate, filterContent);
  },
  methods: {
    init(){
      this.showChart = false;
      this.seriesAge[0].data = [];
      this.chartOptionsAge.xaxis.categories = [];
      this.seriesGender = [];
      this.chartOptionsAveragePurchase.xaxis.categories = [];
      this.seriesAveragePurchase[0].data = [];      
      this.seriesPayment[0].data = [];
      this.chartOptionsPayment.xaxis.categories = [];
      this.seriesLocation[0].data = [];
      this.chartOptionsLocation.xaxis.categories = [];
    },
    formatPrice(value) {
      console.log(value);
      let val = (value / 1).toFixed(0).replace('.', ',')
      return 'Rp.' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async generate(filterBy, filterValue, filterDate, filterContent){
      this.init();
      let queryObject = [
            {
                name: "by",
                value: filterBy
            },
            {
                name: "filter",
                value: filterValue
            },
            {
                name: "content",
                value: filterContent
            }
        ]

        if(filterDate !== 'xxx'){
          const dates = filterDate.split("~");
          queryObject.push({
              name: "start",
              value: dates[0]
          })
          queryObject.push({
              name:"end",
              value: dates[1] ?? dates[0]
          })
      }
      
      let route = 'v1/market/user-demographic-filter?'
      const response = await reportingApi.get(route, queryObject)
      const result = response.data.result;
      if(![undefined, null].includes(result)){
            var ageData = result[0].data;
            if(ageData.length > 0){
                ageData.forEach(item => {
                    this.chartOptionsAge.xaxis.categories.push(item.age == -1 ? 'Uknown Age' : 'Age of ' + item.age);
                    this.seriesAge[0].data.push(item.total)
                })
            }


        var paymentData = result[3].data;
          if(paymentData.length > 0){
              paymentData.forEach(item => {
                  this.chartOptionsPayment.xaxis.categories.push(item.payment_method == -1 ? 'Uknown Payment' : item.payment_method);
                  this.seriesPayment[0].data.push(item.count)
              })
          }

        var genderData = result[1].data;
        if(genderData.length > 0){
            let arrGender = [Number.parseInt(genderData[0].male) ?? 0, Number.parseInt(genderData[0].female) ?? 0, 
            Number.parseInt(genderData[0].uknown) ?? 0];
            this.seriesGender = [...arrGender];
            console.log(this.seriesGender)
        }

        var averagePurchaseData = result[2].data;
        if(averagePurchaseData.length > 0){
            averagePurchaseData.forEach(item => {
              this.chartOptionsAveragePurchase.xaxis.categories.push(item.amount.includes("below") ? "Below " + this.formatPrice(item.amount.split(" ")[1]) : this.formatPrice(item.amount));
              this.seriesAveragePurchase[0].data.push(Number.parseInt(item.purchase_amount) ?? 0)
            })
        }

        var locationData = result[4].data;
          if(locationData.length > 0){
            locationData.forEach(item => {
                  this.chartOptionsLocation.xaxis.categories.push(item.city);
                  this.seriesLocation[0].data.push(item.total_count)
              })
          }
      }
      this.showChart = true;
    },
  },
};
</script>
